import { gsap } from "gsap";
import {
  preloadImages,
  map,
  lerp,
  getMousePos,
  calcWinsize,
  getRandomNumber,
} from "../../utilities/tools";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

//import adel from '../../../public/pictures/lilian.png'

// import components
//import IconLink from 'components/IconLink';

function Portfolio() {
  // Calculate the viewport size
  let winsize = calcWinsize();
  window.addEventListener("resize", () => (winsize = calcWinsize()));

  // Track the mouse position
  let mousepos = { x: winsize.width / 2, y: winsize.height / 2 };
  window.addEventListener("mousemove", (ev) => (mousepos = getMousePos(ev)));

  // amounts to move in each axis
  let translationVals = { tx: 0, ty: 0 };
  let translationVals2 = { tx: 0, ty: 0 };
  let translationVals3 = { tx: 0, ty: 0 };
  let translationVals4 = { tx: 0, ty: 0 };
  let translationVals5 = { tx: 0, ty: 0 };
  let translationVals6 = { tx: 0, ty: 0 };
  let translationVals7 = { tx: 0, ty: 0 };
  let translationVals8 = { tx: 0, ty: 0 };
  let translationVals9 = { tx: 0, ty: 0 };
  let translationVals10 = { tx: 0, ty: 0 };

  // Define images
  const image1 = {
    backgroundImage: 'url("/pictures/miniatures/01.jpeg")',
  };
  const image2 = {
    backgroundImage: 'url("/pictures/miniatures/02.jpeg")',
  };
  const image3 = {
    backgroundImage: 'url("/pictures/miniatures/03.jpeg")',
  };
  const image4 = {
    backgroundImage: 'url("/pictures/miniatures/11.jpeg")',
  };
  const image5 = {
    backgroundImage: 'url("/pictures/miniatures/05.jpeg")',
  };
  const image6 = {
    backgroundImage: 'url("/pictures/miniatures/06.jpeg")',
  };
  const image7 = {
    backgroundImage: 'url("/pictures/miniatures/07.jpeg")',
  };
  const image8 = {
    backgroundImage: 'url("/pictures/miniatures/08.jpeg")',
  };
  const image9 = {
    backgroundImage: 'url("/pictures/miniatures/09.jpeg")',
  };
  const image10 = {
    backgroundImage: 'url("/pictures/miniatures/10.jpeg")',
  };

  const itemsRef = useRef([]);
  itemsRef.current = [];

  const addToRefs = (el) => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el);
    }
  };
  preloadImages(".grid__item-img, .bigimg").then(() => {
    document.body.classList.remove("loading");
  });

  //const cursor = new Cursor(document.querySelector('.cursor'));

  useEffect(() => {
    const xstart = getRandomNumber(15, 60);
    const ystart = getRandomNumber(15, 60);
    const xstart2 = getRandomNumber(15, 60);
    const ystart2 = getRandomNumber(15, 60);
    const xstart3 = getRandomNumber(15, 60);
    const ystart3 = getRandomNumber(15, 60);
    const xstart4 = getRandomNumber(15, 60);
    const ystart4 = getRandomNumber(15, 60);
    const xstart5 = getRandomNumber(15, 60);
    const ystart5 = getRandomNumber(15, 60);
    const xstart6 = getRandomNumber(15, 60);
    const ystart6 = getRandomNumber(15, 60);
    const xstart7 = getRandomNumber(15, 60);
    const ystart7 = getRandomNumber(15, 60);
    const xstart8 = getRandomNumber(15, 60);
    const ystart8 = getRandomNumber(15, 60);
    const xstart9 = getRandomNumber(15, 60);
    const ystart9 = getRandomNumber(15, 60);
    const xstart10 = getRandomNumber(15, 60);
    const ystart10 = getRandomNumber(15, 60);

    const update = () => {
      // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
      translationVals.tx = lerp(
        translationVals.tx,
        map(mousepos.x, 0, winsize.width, -xstart, xstart),
        0.07
      );
      translationVals.ty = lerp(
        translationVals.ty,
        map(mousepos.y, 0, winsize.height, -ystart, ystart),
        0.07
      );
      translationVals2.tx = lerp(
        translationVals2.tx,
        map(mousepos.x, 0, winsize.width, -xstart2, xstart2),
        0.07
      );
      translationVals2.ty = lerp(
        translationVals2.ty,
        map(mousepos.y, 0, winsize.height, -ystart2, ystart2),
        0.07
      );
      translationVals3.tx = lerp(
        translationVals3.tx,
        map(mousepos.x, 0, winsize.width, -xstart3, xstart3),
        0.07
      );
      translationVals3.ty = lerp(
        translationVals3.ty,
        map(mousepos.y, 0, winsize.height, -ystart3, ystart3),
        0.07
      );
      translationVals4.tx = lerp(
        translationVals4.tx,
        map(mousepos.x, 0, winsize.width, -xstart4, xstart4),
        0.07
      );
      translationVals4.ty = lerp(
        translationVals4.ty,
        map(mousepos.y, 0, winsize.height, -ystart4, ystart4),
        0.07
      );
      translationVals5.tx = lerp(
        translationVals5.tx,
        map(mousepos.x, 0, winsize.width, -xstart5, xstart5),
        0.07
      );
      translationVals5.ty = lerp(
        translationVals5.ty,
        map(mousepos.y, 0, winsize.height, -ystart5, ystart5),
        0.07
      );
      translationVals6.tx = lerp(
        translationVals6.tx,
        map(mousepos.x, 0, winsize.width, -xstart6, xstart6),
        0.07
      );
      translationVals6.ty = lerp(
        translationVals6.ty,
        map(mousepos.y, 0, winsize.height, -ystart6, ystart6),
        0.07
      );
      translationVals7.tx = lerp(
        translationVals7.tx,
        map(mousepos.x, 0, winsize.width, -xstart7, xstart7),
        0.07
      );
      translationVals7.ty = lerp(
        translationVals7.ty,
        map(mousepos.y, 0, winsize.height, -ystart7, ystart7),
        0.07
      );
      translationVals8.tx = lerp(
        translationVals8.tx,
        map(mousepos.x, 0, winsize.width, -xstart8, xstart8),
        0.07
      );
      translationVals8.ty = lerp(
        translationVals8.ty,
        map(mousepos.y, 0, winsize.height, -ystart8, ystart8),
        0.07
      );
      translationVals9.tx = lerp(
        translationVals9.tx,
        map(mousepos.x, 0, winsize.width, -xstart9, xstart9),
        0.07
      );
      translationVals9.ty = lerp(
        translationVals9.ty,
        map(mousepos.y, 0, winsize.height, -ystart9, ystart9),
        0.07
      );
      translationVals10.tx = lerp(
        translationVals10.tx,
        map(mousepos.x, 0, winsize.width, -xstart10, xstart10),
        0.07
      );
      translationVals10.ty = lerp(
        translationVals10.ty,
        map(mousepos.y, 0, winsize.height, -ystart10, ystart10),
        0.07
      );
      gsap.set(itemsRef.current[0], {
        x: translationVals.tx,
        y: translationVals.ty,
      });
      gsap.set(itemsRef.current[1], {
        x: translationVals2.tx,
        y: translationVals2.ty,
      });
      gsap.set(itemsRef.current[2], {
        x: translationVals3.tx,
        y: translationVals3.ty,
      });
      gsap.set(itemsRef.current[3], {
        x: translationVals4.tx,
        y: translationVals4.ty,
      });
      gsap.set(itemsRef.current[4], {
        x: translationVals5.tx,
        y: translationVals5.ty,
      });
      gsap.set(itemsRef.current[5], {
        x: translationVals6.tx,
        y: translationVals6.ty,
      });
      gsap.set(itemsRef.current[6], {
        x: translationVals7.tx,
        y: translationVals7.ty,
      });
      gsap.set(itemsRef.current[7], {
        x: translationVals8.tx,
        y: translationVals8.ty,
      });
      gsap.set(itemsRef.current[8], {
        x: translationVals9.tx,
        y: translationVals9.ty,
      });
      gsap.set(itemsRef.current[9], {
        x: translationVals10.tx,
        y: translationVals10.ty,
      });
      requestAnimationFrame(update);
    };
    requestAnimationFrame(update);
  });

  useEffect(() => {
    gsap
      .timeline()
      .set(itemsRef.current, { scale: 0.7, opacity: 0 }, 0)
      .to(
        itemsRef.current,
        {
          duration: 2,
          ease: "Expo.easeOut",
          scale: 1,
          stagger: { amount: 0.6, grid: "auto", from: "center" },
        },
        0
      )
      .to(
        itemsRef.current,
        {
          duration: 3,
          ease: "Power1.easeOut",
          opacity: 0.4,
          stagger: { amount: 0.6, grid: "auto", from: "center" },
        },
        0
      );
  });

  return (
    <motion.div className="contenu">
      <div className="grid">
        <div className="grid__item pos-1" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=FbHN-BgcSBU" }}
          >
            <div className="grid__item-img" style={image1}></div>
          </Link>
        </div>
        <div className="grid__item pos-2" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=wHCH6Oy-Cao" }}
          >
            <div className="grid__item-img" style={image2}></div>
          </Link>
        </div>
        <div className="grid__item pos-3" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=5V7q2feOG5E" }}
          >
            <div className="grid__item-img" style={image3}></div>
          </Link>
        </div>
        <div className="grid__item pos-4" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=8PUvs4TejHQ" }}
          >
            <div className="grid__item-img" style={image4}></div>
          </Link>
        </div>
        <div className="grid__item pos-5" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=KDBIca5fZnk" }}
          >
            <div className="grid__item-img" style={image5}></div>
          </Link>
        </div>
        <div className="grid__item pos-6" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=qx27_8r2zNQ" }}
          >
            <div className="grid__item-img" style={image6}></div>
          </Link>
        </div>
        <div className="grid__item pos-8" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=GTy0TDuaSPk" }}
          >
            <div className="grid__item-img" style={image7}></div>
          </Link>
        </div>
        <div className="grid__item pos-7" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=YiNvddlyVAI" }}
          >
            <div className="grid__item-img" style={image8}></div>
          </Link>
        </div>
        <div className="grid__item pos-9" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=sipe9M2ViVc" }}
          >
            <div className="grid__item-img" style={image9}></div>
          </Link>
        </div>
        <div className="grid__item pos-10" ref={addToRefs}>
          <Link
            target="_blank"
            to={{ pathname: "https://www.youtube.com/watch?v=7Yg9f5N329I" }}
          >
            <div className="grid__item-img" style={image10}></div>
          </Link>
        </div>
      </div>
      <Link to="/">
        <motion.img
          whileHover={{ scale: 1.2, fill: "#FFFFFF" }}
          src="/icons/cancel.svg"
          alt="retour"
          className="retour"
        />
      </Link>
      <h2 className="content__title no-select">
        <span className="content__title-sub">suivez nos</span>
        <span className="content__title">Aventures</span>
      </h2>
    </motion.div>
  );
}

export default Portfolio;
