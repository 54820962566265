import {motion} from 'framer-motion';
import React from "react";
import { Link } from "react-router-dom";

//Ease
const Member = (props) => {
  const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };
  const transition2 = { duration: 2.4, ease: [0.6, 0.01, -0.05, 0.9] };
  const marginName = 35 -  (props.athlete.name.length * 4.5);
  console.log(`marginName`, marginName);
  return (
      <div className='athletePage'>
    
        <motion.div exit={{left:"-100%" , transition:transition2}} className="athletePhotoBloc">
          <motion.div initial={{width: props.imageDetails.width, height: props.imageDetails.height}} animate={{width:"auto", height:"100%" , transition:transition}}  className="thumbnail">
            <div className="frame">
            <img   src={props.athlete.photo} alt='adel' className="athletePhoto"/>
            </div>
          </motion.div>
            <motion.h3 initial={{color:"#ffffff",top:"35%", margin:0}}  animate={{color:"#1e1f13",top:0, marginLeft:marginName+"vw", transition:transition}} className="athleteName">{props.athlete.name}</motion.h3>
        </motion.div>
        <motion.div initial={{opacity:0}} animate={{opacity:1 , transition:transition2}} exit={{left:"+100%" , transition:transition2}} className="athleteInfoBloc">
        <h3 className="athleteSurname">{props.athlete.surname}</h3>
        <div className="athleteText">
          {props.athlete.text}
        </div>
        <div className="athleteVideo">
        <iframe class="youtubeVideo" src={props.athlete.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
          <div className="athleteInstagram">
          <img src={process.env.PUBLIC_URL + '/icons/instagram.svg'} alt='instagram' className="instaLogo"/><span class="instaName">{props.athlete.insta}</span>
          </div>
          <Link to="/About">
            <motion.img  whileHover={{ scale: 1.2, color:"#FFFFFF" }} src={process.env.PUBLIC_URL + '/icons/cancel.svg'} alt='retour' className='cross'/>
          </Link>
        </motion.div>
      </div>

  );
};

export default Member;