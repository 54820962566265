
const SlidePlaceholder = ({ name, image }) => {
    return ( 

        <div className="placeholders__img-wrap js-img-wrap" style={{'--aspect-ratio': '0.8'}}>
          <img
            src={image}
            alt={name}
            className="placeholders__img"
          />
        </div>

     );
};

export default SlidePlaceholder;