
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import './styles/App.scss';

//Store
import athletes from './store/athletesStore';

//Views
import NotFound from './views/NotFound/notFound';
import Home from './views/Home/home';
import About from './views/About/about';
import Member from './views/Member/member';
import Portfolio from './views/Portfolio/portfolio';
import Contact from './views/Contact/contact';

const App = () => {

  const imageDetails = {
    width: 640,
    height: 800
  };

  
  return (
    <Router>
        <Route render={({location}) => (
          <AnimatePresence  initial={true} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path={['/', '/Home' ]} render={() => <Home imageDetails={imageDetails} />} />
              <Route exact path={['/About' ]} render={() => <About imageDetails={imageDetails} />} />
              <Route exact path={['/Portfolio' ]} render={() => <Portfolio/>} />
              <Route exact path={['/Contact' ]} render={() => <Contact/>} />
              <Route exact path={['/Member/:id' ]} render={({match}) => <Member  imageDetails={imageDetails} athlete={athletes.find(p => p.id === match.params.id)}/>} />
              <Route component={NotFound} />
            </Switch>
          </AnimatePresence>
        )} />
    </Router>
  );
}

export default App;

