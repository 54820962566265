const athletes = [
    { id:"achille",
      name: "Achille",
      surname: "Lalande",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/achille.jpg",
      video: "https://www.youtube.com/embed/7Yg9f5N329I",
      insta: "@achillo.discretos"
    },
    { id:"adel",
      name: "Adel",
      surname: "Boumehdi",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/adel.jpg",
      video: "https://www.youtube.com/embed/3SyZL64PaP8",
      insta: "@adel.boumetricks"
    },
    { id:"aymen",
      name: "Aymen",
      surname: "Shorijen",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/aymen.jpg",
      video: "https://www.youtube.com/embed/jY8rPm7BAko",
      insta: "@shorijen"
    },
    { id:"charles",
      name: "Charles",
      surname: "Bavant",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/charles.jpg",
      video: "https://www.youtube.com/embed/Waz1hDrNhmQ",
      insta: "@charly_lh"
    },
    { id:"clement",
      name: "Clement",
      surname: "Paumier",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/clement.jpg",
      video: "https://www.youtube.com/embed/G7HWQMjdzoE",
      insta: "@clement_rdm"
    },
    { id:"dimitri",
      name: "Dimitri",
      surname: "Conan",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/dimitri.jpg",
      video: "https://www.youtube.com/embed/WxseMajiT24",
      insta: "@dimitri_conan"
    },
    { id:"ed",
      name: "Andrea",
      surname: "Boulenger",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/ed.jpg",
      video: "https://www.youtube.com/embed/sTNUU0oy5lg",
      insta: "@ed_lh_ck"
    },
    { id:"gabriel",
      name: "Gabriel",
      surname: "Carpentier",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/gabriel.jpg",
      video: "https://www.youtube.com/embed/dhFh-4pXhFc",
      insta: "@gabriel_conkretepk"
    },
    { id:"gaetan",
      name: "Gaetan",
      surname: "Dupont",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/gaetan.jpg",
      video: "https://www.youtube.com/embed/V2EroZSjfc0",
      insta: "@gaetandupontparkour"
    },
    { id:"hugo",
      name: "Hugo",
      surname: "Saunier",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/hugo.jpg",
      video: "https://www.youtube.com/embed/w0ZkQfA91I4",
      insta: "@saunierhugo"
    },
    { id:"lilian",
      name: "Lilian",
      surname: "Jubert",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/lilian.jpg",
      video: "https://www.youtube.com/embed/DXj9WAL0pvU",
      insta: "@lilian_conkrete"
    },
    { id:"matisse",
      name: "Matisse",
      surname: "Argentin",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/matisse.jpg",
      video: "https://www.youtube.com/embed/WxseMajiT24",
      insta: "@essitam.a"
    },
    { id:"maxime",
      name: "Maxime",
      surname: "Chicot",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/maxime.jpg",
      video: "https://www.youtube.com/embed/WxseMajiT24",
      insta: "@makimaki.parkour"
    },
    { id:"trystan",
      name: "Trystan",
      surname: "Hamon",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/trystan.jpg",
      video: "https://www.youtube.com/embed/8hsfgtYPyYM",
      insta: "@trystan_arens"
    },
    { id:"vadhim",
      name: "Vadhim",
      surname: "Mignot",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/vadhim.jpg",
      video: "https://www.youtube.com/embed/Rs-yGERmFho",
      insta: "@vadhim"
    },
    { id:"wenck",
      name: "Wenck",
      surname: "Cohas",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus hendrerit ligula, eu facilisis nulla pulvinar et. Nunc blandit sed nibh sit amet sagittis. Fusce mollis sapien vel vulputate convallis. Vivamus ipsum ipsum, rhoncus vel metus non, maximus consectetur erat. Sed mattis ullamcorper velit, nec gravida diam egestas quis.",
      photo: "/pictures/members/wenck.jpg",
      video: "https://www.youtube.com/embed/kZVLCAWj8_Y",
      insta: "@wenckvcohas"
    },
  ];

  export default athletes;