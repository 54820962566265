import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import Placeholders from '../../components/placeholders/placeholders';
import Slider from '../../components/slider/slider';
import SlideElement from '../../components/slider/slideElement';
import SlidePlaceholder from '../../components/slider/slidePlaceholder';
import Scroll from '../../utilities/scroll';

import { constants, instances } from '../../store';


const transition = { duration: 1.6, ease: [0.43,0.13,0.23,0.96]};

function About(){
  useEffect(() => {
    constants.isDevice = 'ontouchstart' in window;
    constants.isDevice && document.body.classList.add('is-device');
  
    // set vh css var
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  
    instances.scroll = new Scroll();
    instances.scroll.init();
    
  
    const placeholders = new Placeholders();
    placeholders.init();
  
    instances.slider = new Slider();
    instances.slider.init();
  }, []);
  
  
    return(
      <motion.div initial={{opacity:0}} animate={{opacity:1}} className="app">
    <main>
      <button
        className="button-slider-open js-slider-open"
        type="button"
        aria-label="button open slider"
      >
        <svg className="button-slider-open__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.68 11.617">
          <path className="button-slider-open__icon-square" d="M2.636 11.617H.181A.177.177 0 010 11.436V8.981A.177.177 0 01.181 8.8h2.442a.177.177 0 01.181.181v2.442a.17.17 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M6.073 11.617H3.618a.177.177 0 01-.181-.181V8.981a.177.177 0 01.181-.181H6.06a.177.177 0 01.181.181v2.442a.178.178 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M9.51 11.617H7.055a.177.177 0 01-.181-.181V8.981a.177.177 0 01.181-.181h2.442a.177.177 0 01.181.181v2.442a.178.178 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M2.636 8.18H.181A.177.177 0 010 7.999V5.544a.177.177 0 01.181-.181h2.442a.177.177 0 01.181.181v2.442a.17.17 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M6.073 7.65H3.618a.177.177 0 01-.181-.181V5.014a.177.177 0 01.181-.181H6.06a.177.177 0 01.181.181v2.442a.178.178 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M9.51 6.849H7.055a.177.177 0 01-.181-.181V4.213a.177.177 0 01.181-.181h2.442a.177.177 0 01.181.181v2.442a.171.171 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M2.636 4.742H.181A.177.177 0 010 4.561V2.106a.177.177 0 01.181-.181h2.442a.177.177 0 01.181.181v2.442a.17.17 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M6.073 3.605H3.618a.177.177 0 01-.181-.181V.969a.177.177 0 01.181-.181H6.06a.177.177 0 01.181.181v2.442a.171.171 0 01-.168.194z" />
          <path className="button-slider-open__icon-square" d="M9.51 2.8H7.055a.177.177 0 01-.181-.181V.181A.177.177 0 017.055 0h2.442a.177.177 0 01.181.181v2.442a.166.166 0 01-.168.177z" />
        </svg>
      </button>

      {/* <!-- CONTENT--> */}
      
      <motion.section exit={{opacity:0}} transition={transition} className="content js-content">
        <div className="container">
        <Link to="/"><motion.img  whileHover={{ scale: 1.2, fill:"#FFFFFF" }} className="retour" alt="retour" src={process.env.PUBLIC_URL + `/icons/cancel.svg`}/></Link>
          <div className="content__subheadings">
            <Link  to="/">
              <span className="content__subheading  lien is-md">CONKRETE /</span>
            </Link>
            <span className="content__subheading is-sm">parkour / audiovisuel / depuis 2020</span>
          </div>

          <h2 className="content__heading">
            Collectif et association notre objectif est de diffuser la pratique du parkour, au Havre notamment, par le biais de contenus audio-visuels et d'évènements.
          </h2>
        </div>
      </motion.section>
      {/* <!-- END CONTENT-->

      <!-- PLACEHOLDERS --> */}
      <div className="placeholders js-placeholders">
        <SlidePlaceholder name="Achille" image="/pictures/members/achille.jpg"/>
        <SlidePlaceholder name="Adel" image="/pictures/members/adel.jpg"/>
        <SlidePlaceholder name="Aymen" image="/pictures/members/aymen.jpg"/>
      </div>
      {/* <!-- END PLACEHOLDERS -->

      <!-- SLIDER --> */}
      <div className="slider js-slider">
        <button
          className="slider__button-close js-slider-close"
          type="button"
          aria-label="close slider"
        >
          <svg className="slider__button-close-icon js-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 205 205">
            <g className="js-circle" fill="none" stroke="var(--color-grey-darker)">
              <circle cx="102.5" cy="102.5" r="102"/>
            </g>
            <g className="js-x" fill="none" stroke="var(--color-grey-darker)" strokeLinecap="round">
              <path d="M94 94l16.139 16.139"/>
              <path d="M94 110.492l16.493-16.493"/>
            </g>
          </svg>
        </button>

        <div className="slider__container js-container" data-scroll>
          <SlideElement name="Achille" link="/Member/achille" image="/pictures/members/achille.jpg"/>  
          <SlideElement name="Adel" link="/Member/adel" image="/pictures/members/adel.jpg"/>
          <SlideElement name="Aymen" link="/Member/aymen" image="/pictures/members/aymen.jpg"/>
          <SlideElement name="Charles" link="/Member/charles" image="/pictures/members/charles.jpg"/>
          <SlideElement name="Clement" link="/Member/clement" image="/pictures/members/clement.jpg"/>
          <SlideElement name="Dimitri" link="/Member/dimitri" image="/pictures/members/dimitri.jpg"/>
          <SlideElement name="Ed" link="/Member/ed" image="/pictures/members/ed.jpg"/>
          <SlideElement name="Gabriel" link="/Member/gabriel" image="/pictures/members/gabriel.jpg"/>
          <SlideElement name="Gaetan" link="/Member/gaetan" image="/pictures/members/gaetan.jpg"/>
          <SlideElement name="Hugo" link="/Member/hugo" image="/pictures/members/hugo.jpg"/>
          <SlideElement name="Lilian" link="/Member/lilian" image="/pictures/members/lilian.jpg"/>
          <SlideElement name="Matisse" link="/Member/matisse" image="/pictures/members/matisse.jpg"/>
          <SlideElement name="Maxime" link="/Member/maxime" image="/pictures/members/maxime.jpg"/>  
          <SlideElement name="Trystan" link="/Member/trystan" image="/pictures/members/trystan.jpg"/>
          <SlideElement name="Vadhim" link="/Member/vadhim" image="/pictures/members/vadhim.jpg"/>
          <SlideElement name="Wenck" link="/Member/wenck" image="/pictures/members/wenck.jpg"/>
        </div>
      <div className="slider__progress-wrap js-progress-wrap">
        <div className="slider__progress js-progress"></div>
      </div> 
      </div>
      {/* <!-- END SLIDER --> */}
    </main>
  </motion.div>
    );
  };
  
  export default About;