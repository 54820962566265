import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const transition2 = { duration: 1.6, ease: [0.43, 0.13, 0.23, 0.96] };

const SlideElement = ({ name, link, image }) => {
  return (
    <motion.div
      exit={{}}
      className="slider__item js-item"
      style={{ "--aspect-ratio": "0.8" }}
    >
      <div
        className="slider__item-img-wrap js-img-wrap js-img"
        style={{ "--aspect-ratio": "0.8" }}
        data-building="building-slug"
        data-index="1"
      >
        <motion.img
          exit={{  transition: { transition2 } }}
          src={image}
          alt={name}
          className="slider__item-img"
        />
      </div>

      <div className="slider__item-content">
        <div className="slider__item-heading-wrap">
          <h3 className="slider__item-heading js-heading">{name}</h3>
        </div>

        <Link
          className="slider__item-button-wrap"
          to={link}
          style={{ textDecoration: "none" }}
        >
          <motion.button
            exit={{opacity:0}}
            className="button slider__item-button js-button"
            type="button"
            data-slug="indigo"
          >
            <span className="button__title-mask">
              <div className="button__title-wrap">
                <span className="button__title">En savoir plus</span>
                <span className="button__title--secondary">En savoir plus</span>
              </div>
            </span>

            <span className="button__icon">
              <span className="button__circle"></span>
              <svg
                className="button__svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.002 4.639"
              >
                <path d="M.25 2.264h15.403" />
                <path d="M13.743.354l1.91 1.91-1.919 2.022" />
              </svg>
              <svg
                className="button__svg button__svg--clone"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.002 4.639"
              >
                <path d="M.25 2.264h15.403" />
                <path d="M13.743.354l1.91 1.91-1.919 2.022" />
              </svg>
            </span>
          </motion.button>
        </Link>
      </div>
    </motion.div>
  );
};

export default SlideElement;
