import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {motion} from "framer-motion";

gsap.registerPlugin(TextPlugin);

const Contact = () => {

    const text = useRef(); 

    useEffect(() =>{
        var scramble = gsap.timeline({repeat:-1});
        scramble.to(text.current, {duration: 1, delay:2, text: "prestations", ease: "none"});
        scramble.to(text.current, {duration: 1, delay:2, text: "sessions", ease: "none"});
        scramble.to(text.current, {duration: 1, delay:2, text: "créations", ease: "none"});
        scramble.to(text.current, {duration: 1, delay:2, text: "initiations", ease: "none"});
        scramble.to(text.current, {duration: 1, delay:2, text: "collaborations", ease: "none"});
        scramble.play();
    },[]);
    
return(
    
<motion.div className="contact" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
    <Link to="/"><motion.img whileHover={{ scale: 1.2, fill:"#FFFFFF" }} className="retour" alt="retour" src={process.env.PUBLIC_URL + `/icons/cancel.svg`}/></Link>
    <div className="textContact">Contactes nous au plus <i>vite</i> !</div>
    <div className="row">
        <a className="textContact justeici" id="justeIci" href="mailto:Conkrete@gmail.com">Juste ici</a>
        <a className="textContact mail blanc" href="mailto:Conkrete@gmail.com">Conkrete@gmail.com</a>
    </div>
    <div className="row">
        <p className="textContact gauche">Pour de superbes </p>
        <p className="textContact droite" ref={text}>collaborations</p>
    </div>
    <div className="row">
        <a className="textContact petitTexte blanc" target="_blank" rel="noreferrer"  href="https://www.helloasso.com/associations/conkrete-parkour/adhesions/adhesion-2021-2022-licence-conkrete-parkour">Adhérer</a>
        <a className="textContact petitTexte blanc" target="_blank" rel="noreferrer" href="https://www.helloasso.com/associations/conkrete-parkour/formulaires/1/widget">Nous soutenir</a>
    </div>
    <div className="row">
        <Link target="_blank" to={{pathname: "https://www.facebook.com/Conkrete.Parkour"}}><img className="logo facebook" alt="facebook" src={process.env.PUBLIC_URL + `/icons/facebook.png`} /></Link>
        <Link target="_blank" to={{pathname:"https://www.youtube.com/channel/UC1trxabLK2YBIoltRjDdhSg"}}><img className="logo youtube" alt="youtube" src={process.env.PUBLIC_URL + `/icons/youtube.png`} /></Link>
        <Link target="_blank" to={{pathname:"https://www.instagram.com/conkrete.parkour/"}}><img className="logo instagram" alt="instagram" src={process.env.PUBLIC_URL + `/icons/insta.png`} /></Link>
        <Link target="_blank" to={{pathname:"https://discord.gg/pgJMVW6c"}}><img className="logo discord" alt="discord" src={process.env.PUBLIC_URL + `/icons/discord.png`} /></Link>
    </div>
</motion.div>

);};

export default Contact;