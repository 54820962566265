import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';


// Components
import Loader from "../../components/Home/Loader";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

    return(
      <AnimateSharedLayout type='crossfade'>
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <>
            <motion.div className="app">

             <motion.h1 initial={{top:"-20vh"}} animate={{top:"15vh"}} exit={{top:"-20vh"}} transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }} className="leftTitle">CONKRETE</motion.h1>
             <motion.h1 initial={{top:"120vh"}} animate={{top:"80vh"}} transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }} className="rightTitle">PARKOUR</motion.h1>
             
             <Link to="/About"><motion.p className=" textContact justeici qui" initial={{opacity:0}} animate={{opacity:1}} transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}>Qui-sommes nous?</motion.p></Link>
             <Link to="/Portfolio"><motion.p className=" textContact justeici quoi" initial={{opacity:0}} animate={{opacity:1}} transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}>Nos réalisations</motion.p></Link>
             <Link to="/Contact"><motion.p className="textContact justeici comment" initial={{opacity:0}} animate={{opacity:1}} transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}>Contact</motion.p></Link>
             </motion.div>
            {/* {!loading && ( */}
              <div className='transition-image final'>
                <video
                  autoPlay muted loop
                  class="VideoHome"
                  //transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                  
                  //ayoutId='main-image-1'
                >
                  <source src={process.env.PUBLIC_URL + `/videos/Conkrete.webm`} type="video/webm"/>
                  <source src={process.env.PUBLIC_URL + `/videos/Conkrete.mp4`} type="video/mp4"/>
                  </video>
              </div>
            {/* )} */}
            
          </>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>

    );
  };
  
  export default Home;